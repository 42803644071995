import React, { useState } from 'react';
import { DropdownItem } from '../Types';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import PrintQueue from '../Components/Queues/PrintQueue';
import { WaitingToBePaidToNyc } from '../Components/Queues/WaitingToBePaidToNyc';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const queues: DropdownItem<number>[] = [
  { value: 0, label: 'Print Queue' },
  { value: 1, label: 'Waiting to be paid to NYC Queue' }
];

const QueuesPage = () => {
  const [selectedQueue, setSelectedQueue] = useState<DropdownItem<number>>();

  const handleQueueChange = (value) => {
    setSelectedQueue(value);
  };

  return (
    <>
      <Row className="mt-5">
        <Col md={4}>
          <Form.Group>
            <Select
              isClearable={true}
              options={queues}
              value={selectedQueue}
              onChange={handleQueueChange}
              placeholder="Select a queue"
            />
          </Form.Group>
        </Col>
      </Row>
      {selectedQueue && selectedQueue.value === 0 && <PrintQueue />}
      {selectedQueue && selectedQueue.value === 1 && <WaitingToBePaidToNyc />}
    </>
  );
};

export default withAuthenticationRequired(QueuesPage);
