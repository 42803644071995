import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { customAxiosFetch } from '../../helpers/customFetch';
import { WEFResponse } from '../../Types';
import toast from 'react-hot-toast';

interface IProps {
  onCloseModal: () => void;
  jobId: string;
}
export const ConfirmJobStop = ({ onCloseModal, jobId }: IProps) => {
  const handleStopJob = () => {
    customAxiosFetch<WEFResponse<string>>({
      endpoint: `/job/stop-job/${jobId}`,
      config: { method: 'POST' },
      callback: (result) => {
        if (result.success) toast.success(result.data);
      }
    });
    onCloseModal();
  };
  return (
    <Modal show={jobId !== null} onHide={onCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Stop a job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">Are you sure to stop the job?</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCloseModal}>No</Button>
        <Button onClick={handleStopJob} variant="danger">
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
