import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentsReduxState {
  refreshCreditCardList: boolean;
}

const initialState: PaymentsReduxState = {
  refreshCreditCardList: false
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState: { ...initialState },
  reducers: {
    setRefreshCreditCard: (state, action: PayloadAction<boolean>) => {
      state.refreshCreditCardList = action.payload;
    },
    clearPaymentsSlice: () => {
      return initialState;
    }
  }
});

export const { setRefreshCreditCard, clearPaymentsSlice } = paymentsSlice.actions;

export default paymentsSlice.reducer;
