import type { Route } from '@tanstack/react-location';
import { Navigate } from '@tanstack/react-location';
import HomePage from '../../Pages/HomePage';
import React from 'react';
import ClientsPage from '../../Pages/ClientsPage';
import InvoicingPage from '../../Pages/InvoicingPage';
import PaymentPage from '../../Pages/PaymentPage';
import ReportsPage from '../../Pages/ReportsPage';
import LoginToAuth0Page from '../../Pages/LoginToAuth0Page';
import Authorized from '../../Pages/Authorized';
import JobsPage from '../../Pages/JobsPage';
import ClientDetailsPage from '../../Pages/ClientDetailsPage';
import TicketsPage from '../../Pages/TicketsPage';
import AnalyticsPage from '../../Pages/AnalyticsPage';
import QueuesPage from '../../Pages/QueuesPage';

export const rootRoute: Route[] = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/home',
    element: <HomePage />
  },
  {
    path: '/clients',
    children: [
      {
        path: '/',
        element: <ClientsPage />
      },
      {
        path: '/:clientId',
        element: <ClientDetailsPage />
      }
    ]
  },
  {
    path: '/invoicing',
    element: <InvoicingPage />
  },
  {
    path: '/payment',
    element: <PaymentPage />
  },
  {
    path: '/reports',
    element: <ReportsPage />
  },
  {
    path: '/queues',
    element: <QueuesPage />
  },
  {
    path: '/login',
    element: <LoginToAuth0Page />
  },
  {
    path: '/authorized',
    element: <Authorized />
  },
  {
    path: '/jobs',
    element: <JobsPage />
  },
  {
    path: '/tickets',
    element: <TicketsPage />
  },
  {
    path: '/analytics',
    element: <AnalyticsPage />
  },
  {
    element: <Navigate to="/" />
  }
];
