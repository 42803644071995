import React, { useState } from 'react';
import { Button, Form, Row, Col, Stack } from 'react-bootstrap';
import { setResults } from '../ReduxSlices/resultsSlice';
import { useAppDispatch } from '../hooks';
import { customFetch } from '../helpers/customFetch';
import { setRefreshCreditCard } from '../ReduxSlices/paymentsSlice';

interface IAddCreditCardFormProps {
  clientId: string;
}
function AddCreditCardForm(props: IAddCreditCardFormProps) {
  const defaultFormData = {
    creditCardNumber: '',
    expiry: '',
    nameOnCard: '',
    zipCode: '',
    cvv: ''
  };
  const [formData, setFormData] = useState(defaultFormData);
  const dispatch = useAppDispatch();

  const handleInputChange = (e) => {
    e.target.value = e.target.value.replace(
      ['creditCardNumber', 'expiry', 'zipCode', 'cvv'].includes(e.target.name)
        ? /[^0-9]/gi
        : /[^a-z ]/gi,
      ''
    );

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNameOnCardChange = (e) => {
    e.target.value = e.target.value.replace(/[^a-z0-9 ]/gi, '');

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNewCC = (e) => {
    e && e.preventDefault();
    customFetch({
      endpoint: `/Client/${props.clientId}/AddCreditCard`,
      callback: () => {
        setFormData(defaultFormData);
        dispatch(
          setResults({
            process: 'Adding Credit Card',
            results: 'Credit Card Accepted!'
          })
        );
        dispatch(setRefreshCreditCard(true));
      },
      config: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      },
      skipParseBody: true
    });
  };

  function cardNumberIsValid(cardNumber: string) {
    switch (cardNumber[0]) {
      case '3':
        return cardNumber.length === 15;
      case '4':
        return [13, 16].includes(cardNumber.length);
      case '5':
      case '6':
        return cardNumber.length === 16;
    }
  }

  function cvvIsValid(cvv: string) {
    switch (formData.creditCardNumber[0]) {
      case '3':
        return cvv.length === 4;
      case '4':
      case '5':
      case '6':
        return cvv.length === 3;
    }
  }

  return (
    <Form onSubmit={handleNewCC} className="bg-light">
      <Form.Group>
        <Form.Text>Name on Card</Form.Text>
        <Form.Control
          value={formData.nameOnCard}
          onChange={handleNameOnCardChange}
          placeholder="John Doe"
          name="nameOnCard"
          maxLength={30}
        />
      </Form.Group>
      <Form.Group>
        <Form.Text>
          Card number with no additional characters. Anything besides a number will be ignored.
        </Form.Text>
        <Form.Control
          value={formData.creditCardNumber}
          onChange={handleInputChange}
          name="creditCardNumber"
          placeholder="1234567890123456"
          maxLength={16}
        />
      </Form.Group>
      <Form.Group>
        <Form.Text>
          Expiration date in MMYY format. For example, January, 2026 should be entered as: 0126
        </Form.Text>
        <Form.Control
          value={formData.expiry}
          onChange={handleInputChange}
          placeholder="MMYY"
          name="expiry"
          maxLength={4}
        />
      </Form.Group>
      <Row className="align-items-end">
        <Col>
          <Form.Group>
            <Form.Text>Enter 5 or 9 digit Zip Code, without dashes.</Form.Text>
            <Form.Control
              value={formData.zipCode}
              onChange={handleInputChange}
              placeholder="12345"
              name="zipCode"
              maxLength={9}
              minLength={5}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Text>Enter 3 or 4 digit CVV</Form.Text>
            <Form.Control
              value={formData.cvv}
              onChange={handleInputChange}
              placeholder="1234"
              name="cvv"
              maxLength={4}
              minLength={3}
            />
          </Form.Group>
        </Col>
      </Row>
      <Stack direction="horizontal" gap={2} className="my-2">
        <Button variant="secondary" onClick={() => setFormData(defaultFormData)}>
          Clear
        </Button>
        <Button
          disabled={
            !cardNumberIsValid(formData.creditCardNumber) ||
            !cvvIsValid(formData.cvv) ||
            !formData.expiry ||
            formData.expiry.length !== 4 ||
            !formData.nameOnCard ||
            !formData.zipCode ||
            ![5, 9].includes(formData.zipCode.length)
          }
          type="submit">
          Add Credit Card
        </Button>
      </Stack>
      {/* <div className="bg-warning">
        <>
          card is valid:{" "}
          {cardNumberIsValid(formData.creditCardNumber).toString()}
        </>
        <>{formData.cvv}</>
        <>we have expiry: {formData.expiry}</>
        <>{cardNumberIsValid(formData.creditCardNumber)}</>
        <>{cardNumberIsValid(formData.creditCardNumber)}</>
        <>{cardNumberIsValid(formData.creditCardNumber)}</>
        <>{[5, 9].includes(formData.zipCode.length)}</>
      </div> */}
    </Form>
  );
}

export default AddCreditCardForm;
