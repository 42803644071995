import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { customAxiosFetch } from '../helpers/customFetch';
import { SavedCreditCards, WEFResponse } from '../Types';
import { useAppDispatch, useAppSelector } from '../hooks';
import { clearPaymentsSlice } from '../ReduxSlices/paymentsSlice';

interface Props {
  cardPointeProfileId: string;
  clientId: string;
}
interface ModalMetadata {
  title: string;
  bodyText: string;
  onConfirm: () => void;
}
export default function ClientSavedCreditCards({ cardPointeProfileId, clientId }: Props) {
  const [savedCreditCards, setsavedCreditCards] = useState<SavedCreditCards[]>([]);
  const [show, setShow] = useState(false);
  const [modalMetaData, setModalMetaData] = useState<ModalMetadata>();
  const refreshCreditCardList = useAppSelector((app) => app.payments.refreshCreditCardList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getSavedCreditCards();
  }, []);

  useEffect(() => {
    if (refreshCreditCardList) {
      getSavedCreditCards();
      dispatch(clearPaymentsSlice());
    }
  }, [refreshCreditCardList]);

  const getSavedCreditCards = () => {
    customAxiosFetch<WEFResponse<SavedCreditCards[]>>({
      endpoint: `/client/get-client-credit-cards/${clientId}`,
      config: { method: 'GET' },
      callback: (result) => {
        if (!result.success) return;
        setsavedCreditCards(result.data);
      }
    });
  };

  function handleDeleteCard(cardId: string) {
    customAxiosFetch<{
      success: boolean;
    }>({
      endpoint: `/client/${clientId}/remove-credit-card/${cardId}`,
      config: { method: 'DELETE' },
      callback: (result) => {
        if (!result?.success) return;
        getSavedCreditCards();
      },
      finalCallback: () => {
        handleCloseModal();
      }
    });
  }

  function handleSetPrimaryCard(cardId: string) {
    customAxiosFetch<{
      success: boolean;
    }>({
      endpoint: `/client/${clientId}/set-primary-credit-card/${cardId}`,
      config: { method: 'POST' },
      callback: (result) => {
        if (!result?.success) return;
        getSavedCreditCards();
      },
      finalCallback: () => {
        handleCloseModal();
      }
    });
  }

  const handleCloseModal = () => {
    setModalMetaData(null);
  };

  const handleOpenDeleteModal = (cardId: string) => {
    setModalMetaData({
      title: 'Delete a card',
      bodyText: 'Are you sure to permanently delete a card?',
      onConfirm: () => handleDeleteCard(cardId)
    });
  };

  const handleOpenSetPrimaryModal = (cardId: string) => {
    setModalMetaData({
      title: 'Set primary credit card',
      bodyText: 'Are you sure to set this credit card as primary?',
      onConfirm: () => handleSetPrimaryCard(cardId)
    });
  };

  return (
    <>
      {modalMetaData && (
        <Modal show={modalMetaData ? true : false} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{modalMetaData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">{modalMetaData.bodyText}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModal}>No</Button>
            <Button onClick={modalMetaData.onConfirm} variant="danger">
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Stack className="mt-4">
        <h2>Saved CreditCards</h2>
        <Stack direction="vertical">
          {savedCreditCards.length > 0
            ? savedCreditCards.map((item) => {
                return (
                  <Stack direction="horizontal" key={item.id}>
                    <span className="col-md-2">{item.maskedCardNumber}</span>
                    <span className="col-md-2">{item.expiry}</span>
                    <span className="col-md-2">{item.cardType}</span>
                    {item.isPrimary === false && (
                      <Button variant="link" onClick={() => handleOpenSetPrimaryModal(item.id)}>
                        Set primary
                      </Button>
                    )}
                    <Button variant="link" onClick={() => handleOpenDeleteModal(item.id)}>
                      Delete
                    </Button>
                  </Stack>
                );
              })
            : 'No saved cards'}
        </Stack>
      </Stack>
    </>
  );
}
