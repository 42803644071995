import React, { ChangeEvent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import IgnoreCameraToggle from './Buttons/IgnoreCameraToggle';
import MonitoringListSelect from './FormComponents/MonitoringListSelect';
import NewMonitoringListModal from './NewMonitoringListModal';
import GlobalActionModal from './GlobalActionModal';
import { DropdownItem } from '../Types';
import Select from 'react-select';

interface IClientFormProps {
  formData: any;
  handleDataChange: (arrayItem: string, value: string | boolean) => void;
  handleSubmit: (e?: any) => void;
  clearForm: () => void;
  isLoading?: boolean;
  unassignedMonitoringListId: string;
}

const autoPayToNYCDropdownOptions: DropdownItem<number>[] = [
  { value: 0, label: 'Unassigned' },
  { value: 1, label: 'True' },
  { value: 2, label: 'False' }
];
function makeSelectedAutoPayToNYCOption(id: number) {
  const autoPayToNYCOption: DropdownItem<number> = autoPayToNYCDropdownOptions.find(
    (a) => a.value === id
  );
  return autoPayToNYCOption;
}

function ClientForm({
  formData,
  handleDataChange,
  handleSubmit,
  clearForm,
  isLoading,
  unassignedMonitoringListId
}: IClientFormProps) {
  const [clientIsAlreadyInFreshbooks, setClientIsAlreadyInFreshbooks] = useState(true);
  const [selectedAutoPayToNYC, setSelectedAutoPayToNYC] = useState<DropdownItem<number>>(
    makeSelectedAutoPayToNYCOption(formData.autoPayToNYC)
  );

  const FieldLabel = ({ content }: { content: string }) => (
    <Col md={4} style={{ textAlign: 'left' }}>
      <Form.Label>{content}</Form.Label>
    </Col>
  );

  const SaveButton = ({
    buttonType,
    isLoading
  }: {
    buttonType: 'button' | 'submit';
    isLoading?: boolean;
  }) => (
    <Button
      variant="primary"
      className="mx-1"
      type={buttonType}
      disabled={
        isLoading ||
        formData.monitoringListId === 'NewMonitoringList' ||
        (!formData.name && !formData.freshbooksId)
      }
      onClick={() => {
        handleSubmit();
      }}>
      Save
    </Button>
  );

  const handleClientIsAlreadyInFreshbooks = (event) => {
    setClientIsAlreadyInFreshbooks(event.target.checked);
    clearForm();
  };

  const handleOnKeyDown = (event) => {
    if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };

  const handleFreshbooksIdChange = (event) => {
    if (event.target.value.length > 10) {
      event.preventDefault();
    } else {
      handleDataChange('freshbooksId', event.target.value);
    }
  };

  const handleAutoPayToNYCChange = (option) => {
    setSelectedAutoPayToNYC(option);
    handleDataChange('autoPayToNYC', option.value);
  };

  return (
    <>
      {/* <Stack direction="horizontal" gap={2} className="mb-4"> */}

      {/* </Stack> */}
      <Form onSubmit={(e) => e.preventDefault()}>
        <Form.Check
          type="checkbox"
          label="Client is already in Freshbooks"
          className="me-auto"
          checked={clientIsAlreadyInFreshbooks}
          onChange={handleClientIsAlreadyInFreshbooks}
        />
        {!clientIsAlreadyInFreshbooks ? (
          [
            ['Name', 'Enter Full Name', 'name'],
            ['Email Address', 'Enter Email Address', 'emailAddress'],
            ['Alternate Email Address', 'Enter Alternate Email Address', 'alternateEmailAddress'],
            ['Phone Number', 'Enter Phone Number', 'phoneNumber'],
            ['Alternate Phone Number', 'Enter Alternate Phone Number', 'alternatePhoneNumber']
          ].map((i) => (
            <Row key={i[2] + '-form-control'} className="mb-2">
              <FieldLabel content={i[0]} />
              <Col>
                <Form.Control
                  placeholder={i[1]}
                  value={formData[i[2]]}
                  onChange={(event) => handleDataChange(i[2], event.target.value)}
                />
              </Col>
            </Row>
          ))
        ) : (
          <Row className="mb-2">
            <FieldLabel content={'FreshBooks ID'} />
            <Col>
              <Form.Control
                isValid={formData.freshbooksId && formData.freshbooksId.length <= 10}
                isInvalid={
                  !formData.freshbooksId ||
                  formData.freshbooksId.length > 10 ||
                  formData.freshbooksId === 0
                }
                placeholder="Enter the FreshBooks ID for this Client"
                value={formData.freshbooksId ?? ''}
                type="number"
                inputMode="numeric"
                onChange={handleFreshbooksIdChange}
                onKeyDown={handleOnKeyDown}
              />
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <FieldLabel content={'CardPointe Profile ID (optional)'} />
          <Col>
            <Form.Control
              isValid={formData.cardPointeProfileId}
              name="cardPointeProfileId"
              placeholder="Enter the CardPointe Profile ID for this Client"
              value={formData.cardPointeProfileId === null ? '' : formData.cardPointeProfileId}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleDataChange('cardPointeProfileId', event.target.value)
              }
            />
          </Col>
        </Row>
        <Row>
          <FieldLabel content={'Monitoring'} />
          <Col className="row">
            <Col>
              <MonitoringListSelect
                handleDataChange={handleDataChange}
                value={formData.monitoringListId}
              />
            </Col>
          </Col>
          {formData.monitoringListId === 'NEW' && (
            <Col>
              <NewMonitoringListModal />
            </Col>
          )}
        </Row>
        <Row className="mt-2">
          <FieldLabel content={'NYC Auto Pay'} />
          <Col>
            <Select
              options={autoPayToNYCDropdownOptions}
              value={selectedAutoPayToNYC}
              onChange={handleAutoPayToNYCChange}
            />
          </Col>
        </Row>

        {formData.monitoringListId !== unassignedMonitoringListId && (
          <IgnoreCameraToggle
            value={formData.ignoreCameraViolations}
            handleDataChange={handleDataChange}
          />
        )}
        <div className="d-flex justify-content-end mt-2">
          {formData.name && !formData.freshbooksId ? (
            <GlobalActionModal
              actionText="Creating a New Client"
              warningText="Not providing a valid Freshbooks Id will trigger the system to create a new client in Freshbooks on your behalf. Do you wish to continue?"
              child={<SaveButton isLoading={isLoading} buttonType="button" />}
              proceedCallback={handleSubmit}
            />
          ) : (
            <SaveButton isLoading={isLoading} buttonType="submit" />
          )}
          <Button variant="secondary" onClick={clearForm}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

export default ClientForm;
