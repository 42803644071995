import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import JobDetailsModal from './JobsDetailsModal';
import { Job } from '../../Types';
import { Badge } from 'react-bootstrap';
import { ConfirmJobStop } from './ConfirmJobStop';

interface IProps {
  jobs: Job[];
}

function JobsList({ jobs }: IProps) {
  const [selectedJob, setSelectedJob] = useState(null);
  const [stopJobId, setStopJobId] = useState(null);

  function handleSelectedJob(job: Job) {
    setSelectedJob(job);
  }

  function handleCloseModal() {
    setSelectedJob(null);
  }

  function handleStopJob(jobId: string) {
    setStopJobId(jobId);
  }
  function handleStopJobCloseModal() {
    setStopJobId(null);
  }

  return (
    <div className="mt-2">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>JobType</th>
            <th>Success</th>
            <th>Start time</th>
            <th>End time</th>
            <th>Should stop</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.id}>
              <td>
                <span className="cursor-pointer" onClick={() => handleSelectedJob(job)}>
                  {job.jobType}
                </span>
                {job.jobType === 'GenerateInvoices' && (
                  <Badge
                    className="float-end cursor-pointer"
                    bg="danger"
                    onClick={() => handleStopJob(job.id)}>
                    Stop
                  </Badge>
                )}
              </td>
              <td>{job.success}</td>
              <td>{job.startTime}</td>
              <td>{job.endTime}</td>
              <td>{job.shouldStop}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedJob && <JobDetailsModal onCloseModal={handleCloseModal} selectedJob={selectedJob} />}
      {stopJobId && <ConfirmJobStop onCloseModal={handleStopJobCloseModal} jobId={stopJobId} />}
    </div>
  );
}

export default JobsList;
