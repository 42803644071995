import React from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import { Col, Row } from 'react-bootstrap';
import { FieldLabel } from '../../helpers/utils';

interface Props {
  value: boolean;
  handleDataChange: (action: string, value: boolean) => void;
}

function IgnoreCameraToggle({ value, handleDataChange }: Props) {
  return (
    <Row className="mt-2">
      <FieldLabel content={'Ignore Camera Violations'} />
      <Col>
        <FormCheck
          className="me-auto"
          type="checkbox"
          checked={value}
          onChange={(e) => handleDataChange('ignoreCameraViolations', e.target.checked)}
        />
      </Col>
    </Row>
  );
}

export default IgnoreCameraToggle;
