import React from 'react';
import { setResults } from '../../ReduxSlices/resultsSlice';
import GlobalButton from './GlobalButton';
import { useAppDispatch } from '../../hooks';
import { customFetch } from '../../helpers/customFetch';

interface IZeroOutInvoicesButtonProps {
  invoiceId?: string;
}

function ZeroOutInvoicesButton({ invoiceId = null }: IZeroOutInvoicesButtonProps) {
  const dispatch = useAppDispatch();

  const process = 'Zero-ing Out ' + (invoiceId ? 'Invoice' : 'All Invoices');

  const handleZeroOutInvoices = () => {
    customFetch({
      endpoint: '/Payment/zeroOutInvoices' + (invoiceId ? '/' + invoiceId : ''),
      config: { method: 'POST' },
      processDescription: process,
      callback: () =>
        dispatch(
          setResults({
            process: process,
            results: 'Success!'
          })
        )
    });
  };

  return (
    <GlobalButton
      disabled={true}
      onClick={handleZeroOutInvoices}
      buttonText={invoiceId ? 'Zero Out Invoice' : 'Zero Out All Invoices'}
    />
  );
}

export default ZeroOutInvoicesButton;
