import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { WaitingToBePaidToNycType, WEFResponse } from '../../Types';
import { customAxiosFetch } from '../../helpers/customFetch';

interface IProps {
  clientIdParam?: string;
}
export const WaitingToBePaidToNyc = () => {
  const [waitingToBePaidToNycList, setWaitingToBePaidToNycList] =
    useState<WaitingToBePaidToNycType[]>();

  useEffect(() => {
    customAxiosFetch<WEFResponse<WaitingToBePaidToNycType[]>>({
      endpoint: `/Ticket/get-waiting-to-be-paid-to-nyc`,
      config: { method: 'GET' },
      callback: (result) => {
        if (!result?.success) return;
        setWaitingToBePaidToNycList(result.data);
      }
    });
  }, []);

  const handleRowClick = (clientId: string) => {
    window.open(`/clients/${clientId}`, '_blank');
  };

  return (
    <div className="mt-5">
      {waitingToBePaidToNycList && waitingToBePaidToNycList.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Waiting to be paid to NYC</th>
            </tr>
          </thead>
          <tbody style={{ cursor: 'pointer' }}>
            {waitingToBePaidToNycList.map((waitingToBePaidToNyc) => (
              <tr
                key={waitingToBePaidToNyc.clientId}
                onClick={(e) => {
                  handleRowClick(waitingToBePaidToNyc.clientId);
                }}>
                <td>{waitingToBePaidToNyc.clientName}</td>
                <td>{waitingToBePaidToNyc.waitingToBePaidToNycCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <h3 className="text-center">No tickets are waiting to be paid to NYC</h3>
      )}
    </div>
  );
};
